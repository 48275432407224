@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif; /* Using the imported Google Font */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: 'Courier New', monospace; /* Replace with your desired font */
}

.no-wrap {
  white-space: nowrap;
  font-family: 'Times New Roman', serif; /* Replace with your desired font */
}


